import React, { useEffect } from "react";
import styled from "styled-components";
import { useFavicon } from "react-use";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;


  margin: 0;
  padding: 0 60px;

  @media(max-width: 920px){
    padding: 0 20px;
  }

  @media(max-width: 600px){
    padding 0 18px;
  }
`;

export const App = ({ backgroundColor, children }) => {
  useFavicon(`icons/favicon-${backgroundColor}.png`);
  return <Main>{children}</Main>;
};
