import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) ul:focus ,
body:not(.user-is-tabbing) li:focus {
  outline: none;
}

*:focus{
  outline: #fcfcfc dashed 1px;
}

* {
  cursor: none;
}

html,
html.wf-loading {
  main {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  main {
    opacity: 1;
  }
}


body {
  font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.02em;

  font-weight: normal;
  font-style: normal;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  word-break: break-word;

  // cursor: url(/icons/cursor-small@1x.png) 15 15, pointer;
  // cursor: -webkit-image-set( url(/icons/cursor-small@1x.png) 1x,
  // url(/icons/cursor-small@2x.png) 2x) 15 15, pointer;

  @media(max-width: 600px){
    font-size: 12px;
    line-height: 17px;
  }
}

h1,h2,h3,h4,h5{
  font-weight: normal;

  margin: 0;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;

    padding: 0;
    margin: 0;
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}


/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

.beatrice{
  font-family: "Beatrice-Thin", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;
}


.untitled-reg {
  font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;
}

// em {
//   font-family: "Untitled-Sans-Light-Italic", "Helvetica Neue", "Lucida Grande", sans-serif;
//   font-weight: normal;
//   font-style: normal;
// }


ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-21,
.text-21 > p,
.text-21 > h1 {
  font-size: 21px;
  line-height: 60px;
  letter-spacing: 0.02em;
  font-weight: 200;

  @media(max-width: 920px){
    font-size: 18px;
  }

  @media(max-width: 600px){
    font-size: 12px;
    line-height: 30px;
  }
}

.text-16,
.text-16 > p  {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-weight: 200;

  @media(max-width: 600px){
    font-size: 12px;
    line-height: 17px;
  }
}


/*--------------------------------------------------------------
  ## Text Colors
  --------------------------------------------------------------*/

.text-white{
  color: #FCFCFC;
}


/*--------------------------------------------------------------
  ## Z-Index
  --------------------------------------------------------------*/

.z-index--1{
  z-index: -1;
}

.z-index-0{
  z-index: 0;
}

.z-index-100{
  z-index: 100;
}

/*--------------------------------------------------------------
  ## Flex
  --------------------------------------------------------------*/

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.baseline{
    align-items: baseline;
  }

  &.flex-end{
    align-self: flex-end;
  }
}


/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Inputs
  --------------------------------------------------------------*/

  input {
    background-color: transparent;
    border-radius: 0;
  }

::-webkit-input-placeholder{
    font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
    color: rgba(0,0,0,0.5);
    font-size: 17px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 18px;
    }
}
::-moz-placeholder{
    font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
    color: rgba(0,0,0,0.5);
    font-size: 17px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 18px;
    }

}
:-ms-input-placeholder{
    font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
    color: rgba(0,0,0,0.5);
    font-size: 17px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 18px;
    }

}
:-moz-placeholder {
    font-family: "Untitled-Sans-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
    color: rgba(0,0,0,0.5);
    font-size: 17px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 18px;
    }

}
`;

export default GlobalStyle;
