import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import Div100vh from "react-div-100vh";

// Context
import { PageColor } from "../context/page-color";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;
  transition-delay: 250ms;
`;

const randomArrayItem = items =>
  items[Math.floor(Math.random() * items.length)];

const Layout = ({ children }) => {
  const bgColors = [`#22BB6F`, `#FF532E`];
  const [backgroundColor, setBackgroundColor] = useState(null);

  useEffect(() => {
    setBackgroundColor(randomArrayItem(bgColors));
  }, []);

  useEffect(() => {
    if (backgroundColor !== null) {
      document.body.style.backgroundColor = backgroundColor;
    }
  }, [backgroundColor]);

  // Script for dealing with focus on tab key rather than on click
  // https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
  const handleFirstTab = e => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);
  }, []);

  return (
    <Div100vh>
      <PageColor.Provider value={[backgroundColor, setBackgroundColor]}>
        <Container opacity={backgroundColor === null ? 0 : 1}>
          <Normalize />
          <GlobalStyles />
          <DefaultSEO />

          <App
            children={children}
            backgroundColor={
              backgroundColor !== null
                ? backgroundColor === `#22BB6F`
                  ? `green`
                  : `orange`
                : ``
            }
          />
        </Container>
      </PageColor.Provider>
    </Div100vh>
  );
};

export default Layout;
